a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.btn-plex {
    --bs-btn-color: #000;
    --bs-btn-bg: #e5a00d;
    --bs-btn-border-color: #e5a00d;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #e9ae31;
    --bs-btn-hover-border-color: #e8aa25;
    --bs-btn-focus-shadow-rgb: 195, 136, 11;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #eab33d;
    --bs-btn-active-border-color: #e8aa25;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #e5a00d;
    --bs-btn-disabled-border-color: #e5a00d;
}
